<template>
  <div v-if="data">
    <b-row class="text-center mb-1" style="font-size: 0.8rem">
      <b-col>% Score Player / Score team</b-col>
      <b-col>% Score Team Without Player / Score team</b-col>
      <b-col>% Score Starting Player / Score team</b-col>
      <b-col>% Score Substitute Player / Score team</b-col>
    </b-row>
    <b-row>
      <b-col class="text-center">
        <Progress
          class="principle"
          :strokeColor="getColorRank(data.param_a)"
          :value="data.param_a"
          :radius="30"
          :strokeWidth="5"
          :transitionDuration="0"
        ></Progress>
      </b-col>
      <b-col class="text-center"
        ><Progress
          class="principle"
          :strokeColor="getColorRank(data.param_b)"
          :value="data.param_b"
          :radius="30"
          :strokeWidth="5"
          :transitionDuration="0"
        ></Progress>
      </b-col>
      <b-col class="text-center"
        ><Progress
          class="principle"
          :strokeColor="getColorRank(data.param_c)"
          :value="data.param_c"
          :radius="30"
          :strokeWidth="5"
          :transitionDuration="0"
        ></Progress
      ></b-col>
      <b-col class="text-center"
        ><Progress
          class="principle"
          :strokeColor="getColorRank(data.param_d)"
          :value="data.param_d"
          :radius="30"
          :strokeWidth="5"
          :transitionDuration="0"
        ></Progress
      ></b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-table-simple small fixed style="font-size: 0.7rem">
          <b-thead>
            <b-tr>
              <b-th class="text-center">Games Team</b-th>
              <b-th class="text-center">Starting Player</b-th>
              <b-th class="text-center">Substitute Player</b-th>
              <b-th class="text-center">Score Team</b-th>
              <b-th class="text-center">Score Player</b-th>
              <b-th class="text-center">Score Starting Player</b-th>
              <b-th class="text-center">Score Substitute Player</b-th>
              <b-th class="text-center">Score Team Without Player</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr style="font-size: 0.9rem">
              <b-td class="text-center"
                >{{ data.partiteCount }} /
                <span style="color: yellow">{{
                  data.partiteCountTotal
                }}</span></b-td
              >
              <b-td class="text-center">{{ data.titolareCount }}</b-td>
              <b-td class="text-center">{{ data.sostituzioniCount }}</b-td>
              <b-td class="text-center"
                >{{ data.scoreTeam }} /
                <span style="color: yellow">{{
                  data.scoreTeamTotal
                }}</span></b-td
              >
              <b-td class="text-center">{{ data.scorePlayer }}</b-td>
              <b-td class="text-center">{{ data.scorePlayerTitolare }}</b-td>
              <b-td class="text-center">{{
                data.scorePlayerSostituzione
              }}</b-td>
              <b-td class="text-center"
                >{{ data.scoreTeamWithoutPlayer }} /
                <span style="color: yellow">{{
                  data.scoreTeamTotalWithoutPlayer
                }}</span></b-td
              >
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Progress from "easy-circular-progress";
export default {
  props: {
    player_id: {
      type: Number,
    },
  },

  data: function () {
    return {
      data: null,
      bg_opta: [
        "#00B386",
        "#2EC85B",
        "#7BDE3C",
        "#B8F100",
        "#E9F700",
        "#FFF300",
        "#FFDB00",
        "#FFB800",
        "#FF8900",
        "#FF0059",
      ],
    };
  },

  components: {
    Progress,
  },

  created: function () {
    if (this.player_id) {
      this.$http
        .get("/scouting/params/player/" + this.player_id)
        .then((response) => {
          this.data = response.data;
        });
    }
  },

  methods: {
    getColorRank(value) {
      var index = 0;
      if (value >= 0 && value <= 10) {
        index = 9;
      } else if (value > 10 && value <= 20) {
        index = 8;
      } else if (value > 20 && value <= 30) {
        index = 7;
      } else if (value > 30 && value <= 40) {
        index = 6;
      } else if (value > 40 && value <= 50) {
        index = 5;
      } else if (value > 50 && value <= 60) {
        index = 4;
      } else if (value > 60 && value <= 70) {
        index = 3;
      } else if (value > 70 && value <= 80) {
        index = 2;
      } else if (value > 80 && value <= 90) {
        index = 1;
      } else if (value > 90 && value <= 100) {
        index = 0;
      }

      return this.bg_opta[index];
    },
  },
};
</script>
